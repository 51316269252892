<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>
        Pencarian Laporan
      </h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2 d-none"
        >
          <b-form-group
            label="Nama"
            label-for="account-username"
          >
            <b-form-input
              :value="namaUserFilter"
              placeholder="Nama"
              name="username"
              class="form-control"
              @input="(val) => $emit('update:namaUserFilter', val)"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2 d-none"
        >
          <b-form-group
            label="Umur"
            label-for="account-username"
          >
            <b-row>
              <b-col
                cols="4"
                md="3"
              >
                <b-form-input
                  :value="umurAwalFilter"
                  placeholder="17"
                  name="username"
                  class="form-control"
                  @input="(val) => $emit('update:umurAwalFilter', val)"
                  col="3"
                  md="3"
                />
              </b-col>
              <b-col
                cols="1"
                md="1"
                class="pt-md-1 pt-1"
              >
                <label>s/d</label>
              </b-col>
              <b-col
                cols="4"
                md="3"
              >
                <b-form-input
                  :value="umurAkhirFilter"
                  placeholder="35"
                  name="username"
                  class="form-control"
                  @input="(val) => $emit('update:umurAkhirFilter', val)"
                  col="3"
                  md="3"
                />
              </b-col>
            </b-row>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2 d-none"
        >
            <b-form-group
            label="Jenis Kelamin"
            label-for="account-username"
          >
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :value="jenisKelaminFilter"
              :options="jenisKelaminOptions"
              class="w-100"
              multiple
              @input="(val) => $emit('update:jenisKelaminFilter', val)"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2 d-none"
        >
          <b-form-group
            label="Provinsi"
            label-for="account-username"
          >
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :value="provinsiFilter"
              :options="provinsiOptions"
              :reduce="val => val.value"
              @input="(val) => changeProv(val)"
              class="w-100"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2 d-none"
        >
          <b-form-group
            label="Kota"
            label-for="account-username"
          >
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :value="kotaFilter"
              :options="kotaOptions"
              class="w-100"
              @input="(val) => $emit('update:kotaFilter', val)"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
          v-if="isKoordinator === false"
        >
          <b-form-group
            label="Proyek"
            label-for="account-username"
          >
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              v-model="searchFilter.proyek_id"
              :options="proyekOptions"
              :reduce="val => val.value"
              class="w-100"
              @input="(val) => changeKegiatan(val)"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <b-form-group
            label="Kegiatan"
            label-for="account-username"
          >
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              v-model="searchFilter.kegiatan_id"
              :options="kegiatanOptions"
              class="w-100"
              multiple
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <b-form-group
            label="Tag Admin"
            label-for="account-username"
          >
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              v-model="searchFilter.tag_admin_id"
              :options="tagAdminOptions"
              :reduce="val => val.value"
              class="w-100"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Tag Publik</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            v-model="searchFilter.tag_publik_id"
            :options="tagPublikOptions"
            class="w-100"
            :reduce="val => val.value"
          />
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <b-form-group
            label="Periode Awal"
          >
            <flat-pickr
              v-model="searchFilter.tanggal_awal"
              class="form-control"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <b-form-group
            label="Periode Akhir"
          >
            <flat-pickr
              v-model="searchFilter.tanggal_akhir"
              class="form-control"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12 mt-2"
        >
          <b-button
            variant="primary"
            @click="cariData()"
            class="mr-1"
          >
            <span class="text-nowrap">Cari</span>
          </b-button>
          <b-button
            variant="success"
            @click="resetForm()"
            class="mr-1"
          >
            <span class="text-nowrap">Reset</span>
          </b-button>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BButton,
} from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    vSelect,
    BFormGroup,
    BFormInput,
    flatPickr,
    // BFormCheckbox,
    BButton,
    // Cleave,
  },
  props: {
    tagAdminOptions: {
      type: Array,
    },
    tagPublikOptions: {
      type: Array,
    },
    kegiatanOptions: {
      type: Array,
      required: true,
    },
    proyekOptions: {
      type: Array,
      required: true,
    },
    kotaOptions: {
      type: Array,
      required: true,
    },
    provinsiOptions: {
      type: Array,
      required: true,
    },
    jenisKelaminOptions: {
      type: Array,
      required: true,
    },
    tagFilter: {},
    tagFilterPublik: {},
    namaUserFilter: null,
    jenisKelaminFilter: null,
    provinsiFilter: null,
    kotaFilter: null,
    newFilter: null,
    proyekFilter: null,
    umurAwalFilter: null,
    umurAkhirFilter: null,
    kegiatanFilter: {},
    isKoordinator: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      btnKey: 0,
      searchFilter: {
        proyek_id: null,
        kegiatan_id: null,
        tag_admin_id: null,
        tag_publik_id: null,
        tanggal_awal: null,
        tanggal_akhir: null,
      },
      options: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
        },
      },
      statusOption: [
        {
          id: 0,
          label: 'Publik',
        },
        {
          id: 1,
          label: 'Privat',
        },
      ],
    }
  },
  mounted() {
    const d = new Date()
    const tanggalAwal = d.setMonth(d.getMonth() - 6)
    this.searchFilter.tanggal_awal = tanggalAwal
    const dn = new Date()
    const tanggalAkhir = dn.setMonth(dn.getMonth() + 3)
    this.searchFilter.tanggal_akhir = tanggalAkhir
  },
  methods: {
    resetForm() {
      this.searchFilter = {
        proyek_id: null,
        kegiatan_id: null,
        tag_admin_id: null,
        tag_publik_id: null,
        tanggal_awal: null,
        tanggal_akhir: null,
      }
    },
    cariData() {
      // console.log(this.searchFilter)
      this.btnKey += 1
      this.$emit('update:newFilter', this.searchFilter)
      this.$emit('update:btnKey', this.btnKey)
    },
    changeProv(value) {
      this.$emit('update:provinsiFilter', value)
      this.$emit('emitKota', value)
    },
    changeKegiatan(value) {
      this.$emit('emitReceiverKegiatan', value)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
