<template>

  <div>
    <list-filters
      :nama-user-filter.sync="namaUserFilter"
      :jenis-kelamin-filter.sync="jenisKelaminFilter"
      :provinsi-filter.sync="provinsiFilter"
      :kota-filter.sync="kotaFilter"
      :proyek-filter.sync="proyekFilter"
      :umur-awal-filter.sync="umurAwalFilter"
      :umur-akhir-filter.sync="umurAkhirFilter"
      :kegiatan-filter.sync="kegiatanFilter"
      :new-filter.sync="newFilter"
      :btn-key.sync="btnKey"
      :proyekOptions="proyekOptions.options"
      :kegiatanOptions="kegiatanOptions.options"
      :provinsiOptions="provinsiOptions.options"
      :kotaOptions="kotaOptions.options"
      :jenisKelaminOptions="jenisKelaminOptions.options"
      :tagAdminOptions="tagAdminOptions.options"
      :tagPublikOptions="tagPublikOptions.options"
      @emitKota="emitKota"
      @emitReceiverKegiatan="reloadKegiatanOptions"
      :isKoordinator="isKoordinator()"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <!-- <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              /> -->
              <b-button
                variant="primary"
                @click="exportExcel"
              >
                <span class="text-nowrap">Download Laporan</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="fetchDatas"
        responsive
        :fields="tableColumns"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- <template #cell(aktivitas)="data">
          <p v-for="item in data.item.aktivitas" :key="item.id">{{item.nama}}</p>
        </template>

        <template #cell(nilai_aktivitas)="data">
          <p v-for="item in data.item.aktivitas" :key="item.id">{{item.nilai}}</p>
        </template> -->

        <!-- Column: Actions -->
        <template #cell(actions)="data">

            <!-- <b-button class="mr-1 btn-icon btn btn-relief-primary" variant="primary" :to="{ name: 'asset-viewx', params: { id: data.item.id } }"
            >
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Details</span>
            </b-button> //-->

            <b-button class="mr-1 btn-icon btn btn-relief-primary" variant="success" :to="{ name: 'status-asset-edit', params: { id: data.item.id } }" title="Ubah Data"
              v-if="$can('update', 'asset')"
            >
              <feather-icon icon="EditIcon" />
              <!-- <span class="align-middle ml-50">Edit</span> //-->
            </b-button>

            <b-button
              class="btn-icon btn btn-relief-primary"
              variant="warning"
             @click="deleteData(data.item.id)" title="Hapus Data" alt="Hapus Data"
            >
              <feather-icon icon="TrashIcon" />
              <!-- <span class="align-middle ml-50">Delete</span> //-->
            </b-button>
          <!-- </b-dropdown> -->
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Menampilkan {{ dataMeta.from }} per {{ dataMeta.to }} dari {{ dataMeta.of }} data </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
    <div
      class="d-flex justify-content-center mb-1"
      style="position: fixed;top:50%;left:50%"
      v-if="showLoader"
    >
      <b-card>
        <b-card-text>
          <strong>Sedang menunggu proses export data</strong>
        </b-card-text>
        <b-spinner
          v-for="variant in variants"
          :key="variant"
          :variant="variant"
          class="mr-1"
          type="grow"
        />
      </b-card>
    </div>
  </div>
</template>

<script>
import {
  BCard, BCardText, BRow, BCol, BFormInput, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BSpinner,
  BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import {
  getAllProyek, getJenisKelamin, getProvinsi, getKota,
} from '@/api/proyek'
import {
  getAllKegiatanByProyek,
  getAllKegiatanByUser,
  exportExcelDaftarUserKegiatan,
} from '@/api/kegiatan'
import {
  getTag,
} from '@/connection-api/master'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { avatarText } from '@core/utils/filter'
import storeModule from '@/connection-api/daftar-user-kegiatan/storeModule'
import useList from '@/connection-api/daftar-user-kegiatan/list'
import listFilters from './ListFilters.vue'

export default {
  components: {
    listFilters,

    BCard,
    BCardText,
    BRow,
    BCol,
    // BFormInput,
    BTable,
    BButton,
    // BMedia,
    // BAvatar,
    // BLink,
    // BDropdown,
    // BDropdownItem,
    BPagination,
    BSpinner,
    vSelect,
  },
  data() {
    return {
      showLoader: false,
      variants: ['primary', 'secondary', 'danger', 'warning', 'success', 'info', 'primary'],
      tagAdminOptions: {
        selected: 'id',
        options: [],
      },
      tagPublikOptions: {
        selected: 'id',
        options: [],
      },
      proyekOptions: {
        selected: 'id',
        options: [
          { value: null, label: 'Semua' },
        ],
      },
      kegiatanOptions: {
        selected: 'id',
        options: [
          { value: null, label: 'Semua' },
        ],
      },
      jenisKelaminOptions: {
        selected: 'id',
        options: [
          { value: null, label: 'Semua' },
        ],
      },
      provinsiOptions: {
        selected: 'id',
        options: [
          { value: null, label: 'Semua' },
        ],
      },
      kotaOptions: {
        selected: 'id',
        options: [
          { value: null, label: 'Semua' },
        ],
      },
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  async mounted() {
    // console
    const tagAdmin = await getTag(1)
    this.mappingSelectOption(tagAdmin.data, this.tagAdminOptions.options)
    const tagPublik = await getTag(2)
    this.mappingSelectOption(tagPublik.data, this.tagPublikOptions.options)
    const response = await getAllProyek({ byme: true })
    this.mappingSelectOption(response.data.data, this.proyekOptions.options)

    const responseJK = await getJenisKelamin()
    this.mappingSelectOption(responseJK.data, this.jenisKelaminOptions.options)

    const responsePRV = await getProvinsi()
    this.mappingSelectOption(responsePRV.data, this.provinsiOptions.options)

    const isKoordinator = this.isKoordinator()
    if (isKoordinator === true) {
      this.kegiatanOptions = {
        selected: 'id',
        options: [
          { value: null, label: 'Semua' },
        ],
      }
      const responseKegiatan = await getAllKegiatanByUser(1, this.userData.id)
      this.mappingSelectOption(responseKegiatan.data.data, this.kegiatanOptions.options)
    }
  },
  methods: {
    isKoordinator() {
      let isKoordinator = false
      if (this.userData.roles.length > 0) {
        const roles = this.userData.roles[0]
        if (roles === 'koordinator') {
          isKoordinator = true
        }
      }
      return isKoordinator
    },
    async exportExcel() {
      this.showLoader = true
      const filterData = {
        filterData: this.newFilter,
      }
      const result = await exportExcelDaftarUserKegiatan(filterData)
      const url = window.URL.createObjectURL(new Blob([result.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'Daftar User Kegiatan.xlsx')
      document.body.appendChild(link)
      link.click()
      this.showLoader = false
      window.location.reload()
    },
    mappingSelectOption(master, name) {
      if (master.length > 0) {
        master.forEach(element => {
          name.push({
            label: element.nama,
            value: element.id,
          })
        })
      }
    },
    async reloadKegiatanOptions(proyekId) {
      this.kegiatanOptions = {
        selected: 'id',
        options: [
          { value: null, label: 'Semua' },
        ],
      }
      const response = await getAllKegiatanByProyek(1, proyekId)
      this.mappingSelectOption(response.data.data, this.kegiatanOptions.options)
    },
    async emitKota(provinsiId) {
      this.kotaOptions = {
        selected: 'id',
        options: [
          { value: null, label: 'Semua' },
        ],
      }
      const responseKota = await getKota(provinsiId)
      this.mappingSelectOption(responseKota.data, this.kotaOptions.options)
    },
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'daftar-user-kegiatan'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const isAddNewUserSidebarActive = ref(false)

    const {
      fetchDatas,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      namaUserFilter,
      jenisKelaminFilter,
      provinsiFilter,
      kotaFilter,
      proyekFilter,
      umurAwalFilter,
      umurAkhirFilter,
      kegiatanFilter,
      newFilter,
      btnKey,
    } = useList()

    return {

      // Sidebar
      isAddNewUserSidebarActive,

      fetchDatas,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      namaUserFilter,
      jenisKelaminFilter,
      provinsiFilter,
      kotaFilter,
      proyekFilter,
      umurAwalFilter,
      umurAkhirFilter,
      kegiatanFilter,
      newFilter,
      btnKey,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
